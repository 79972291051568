<template>
  <div>
    <v-container class="container--fluid">
      <v-row dense>
        <v-col cols="12">
          <v-card>
            <v-col cols="12">
              <v-row>
                <v-col cols="2" sm="2" md="2" class="pr-3">
                  <v-select
                    v-model="analiysis_typeModel"
                    :items="analiysis_type"
                    label="Raport Tipi"
                  />
                </v-col>

                <v-col cols="1" sm="1" md="1" class="pr-3">
                  <v-select v-model="reportTypeModel" :items="reportType" />
                </v-col>

                <v-col cols="3" sm="2" md="2" class="pr-3">
                  <v-select
                    v-model="santralModel"
                    :items="santralDataList"
                    label="Santral"
                  />
                </v-col>

                <v-spacer />

                <v-col cols="1" sm="1" md="1" class="pr-3">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        fab
                        dark
                        small
                        color="cyan"
                        v-on="on"
                        @click="exportDocument"
                      >
                        <v-icon>mdi-download</v-icon>
                      </v-btn>
                    </template>
                    <span>Export to Excel</span>
                  </v-tooltip>
                </v-col>
              </v-row>
            </v-col>
          </v-card>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col v-if="analiysis_typeModel == 'generalStats'" cols="12">
          <v-card>
            <v-col cols="12" class="pl-0 pr-0 pb-1">
              <v-row>
                <v-spacer />

                <v-col
                  v-if="['Daily'].indexOf(reportTypeModel) > -1"
                  cols="3"
                  sm="2"
                  md="2"
                  class="pr-3"
                >
                  <v-menu
                    ref="menu"
                    v-model="menu"
                    :close-on-content-click="false"
                    :return-value.sync="dates"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-combobox
                        v-model="dates"
                        multiple
                        chips
                        small-chips
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      >
                        <template v-slot:selection="{ item, index }">
                          <span
                            v-if="index === 0"
                            style="max-width: 120px"
                            class="d-inline-block text-truncate"
                            >{{ item }}</span
                          >
                          <span
                            v-if="index === 1"
                            class="grey--text text-caption"
                            >(+{{ dates.length - 1 }})</span
                          >
                        </template>
                      </v-combobox>
                    </template>

                    <v-date-picker
                      v-model="dates"
                      type="month"
                      multiple
                      no-title
                      scrollable
                    >
                      <v-spacer />
                      <v-btn text color="primary" @click="menu = false">
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.menu.save(dates)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-col>

                <v-col
                  v-if="['Minute', 'Hourly'].indexOf(reportTypeModel) > -1"
                  cols="3"
                  sm="2"
                  md="2"
                  class="pr-3"
                >
                  <v-menu
                    ref="menu"
                    v-model="menu"
                    :close-on-content-click="false"
                    :return-value.sync="dates"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-combobox
                        v-model="dates"
                        multiple
                        chips
                        small-chips
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      >
                        <template v-slot:selection="{ item, index }">
                          <span
                            v-if="index === 0"
                            style="max-width: 120px"
                            class="d-inline-block text-truncate"
                            >{{ item }}</span
                          >
                          <span
                            v-if="index === 1"
                            class="grey--text text-caption"
                            >(+{{ dates.length - 1 }})</span
                          >
                        </template>
                      </v-combobox>
                    </template>

                    <v-date-picker v-model="dates" multiple no-title scrollable>
                      <v-spacer />
                      <v-btn text color="primary" @click="menu = false">
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.menu.save(dates)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-col>

                <v-col
                  v-if="reportTypeModel == 'Monthly'"
                  cols="3"
                  sm="1"
                  md="1"
                  class="pr-3"
                >
                  <v-select
                    v-model="yearModel"
                    multiple
                    :items="yearList"
                    label="Yıl"
                  >
                    <template v-slot:selection="{ item, index }">
                      <span
                        v-if="index === 0"
                        style="max-width: 120px"
                        class="d-inline-block text-truncate"
                        >{{ item }}</span
                      >
                      <span v-if="index === 1" class="grey--text text-caption"
                        >(+{{ yearModel.length - 1 }})</span
                      >
                    </template>
                  </v-select>
                </v-col>

                <v-col cols="3" sm="3" md="3" class="pr-3 mt-4">
                  <v-select
                    v-model="measuresModel"
                    :items="measures"
                    label="Ölçü"
                    dense
                  />
                </v-col>

                <v-col cols="1" sm="1" md="1" class="pr-3">
                  <v-select
                    v-model="chartTypeModel"
                    :items="chartType"
                    label="Grafik"
                  />
                </v-col>

                <v-col cols="3" sm="1" md="1" class="pr-3 pt-3">
                  <v-btn color="primary" @click="getData"> Filtrele </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-card>
        </v-col>

        <v-col v-if="analiysis_typeModel == 'INVERTER'" cols="12">
          <v-card>
            <v-col cols="12" class="pl-0 pr-0 pb-1">
              <v-row>
                <v-spacer />
                <v-col
                  v-if="['Daily'].indexOf(reportTypeModel) > -1"
                  cols="3"
                  sm="2"
                  md="2"
                  class="pr-3"
                >
                  <v-menu
                    ref="menu"
                    v-model="menu"
                    :close-on-content-click="false"
                    :return-value.sync="dates"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-combobox
                        v-model="dates"
                        multiple
                        chips
                        small-chips
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      >
                        <template v-slot:selection="{ item, index }">
                          <span
                            v-if="index === 0"
                            style="max-width: 120px"
                            class="d-inline-block text-truncate"
                            >{{ item }}</span
                          >
                          <span
                            v-if="index === 1"
                            class="grey--text text-caption"
                            >(+{{ dates.length - 1 }})</span
                          >
                        </template>
                      </v-combobox>
                    </template>

                    <v-date-picker
                      v-model="dates"
                      type="month"
                      multiple
                      no-title
                      scrollable
                    >
                      <v-spacer />
                      <v-btn text color="primary" @click="menu = false">
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.menu.save(dates)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-col>

                <v-col
                  v-if="['Minute', 'Hourly'].indexOf(reportTypeModel) > -1"
                  cols="3"
                  sm="2"
                  md="2"
                  class="pr-3"
                >
                  <v-menu
                    ref="menu"
                    v-model="menu"
                    :close-on-content-click="false"
                    :return-value.sync="dates"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-combobox
                        v-model="dates"
                        multiple
                        chips
                        small-chips
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      >
                        <template v-slot:selection="{ item, index }">
                          <span
                            v-if="index === 0"
                            style="max-width: 120px"
                            class="d-inline-block text-truncate"
                            >{{ item }}</span
                          >
                          <span
                            v-if="index === 1"
                            class="grey--text text-caption"
                            >(+{{ dates.length - 1 }})</span
                          >
                        </template>
                      </v-combobox>
                    </template>

                    <v-date-picker v-model="dates" multiple no-title scrollable>
                      <v-spacer />
                      <v-btn text color="primary" @click="menu = false">
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.menu.save(dates)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-col>

                <v-col
                  v-if="reportTypeModel == 'Monthly'"
                  cols="3"
                  sm="1"
                  md="1"
                  class="pr-3"
                >
                  <v-select
                    v-model="yearModel"
                    multiple
                    :items="yearList"
                    label="Yıl"
                  >
                    <template v-slot:selection="{ item, index }">
                      <span
                        v-if="index === 0"
                        style="max-width: 120px"
                        class="d-inline-block text-truncate"
                        >{{ item }}</span
                      >
                      <span v-if="index === 1" class="grey--text text-caption"
                        >(+{{ yearModel.length - 1 }})</span
                      >
                    </template>
                  </v-select>
                </v-col>

                <v-col cols="3" sm="2" md="2" class="pr-3">
                  <v-select
                    v-model="inverter"
                    :items="inverterList[santralModel]"
                    label="İnverter"
                  />
                </v-col>

                <v-col cols="2" sm="2" md="2" class="pr-3 mt-4">
                  <v-select
                    v-model="measuresModel"
                    :items="measures"
                    label="Ölçü"
                    dense
                  />
                </v-col>

                <v-col cols="1" sm="1" md="1" class="pr-3">
                  <v-select
                    v-model="chartTypeModel"
                    :items="chartType"
                    label="Grafik"
                  />
                </v-col>

                <v-col cols="3" sm="1" md="1" class="pr-3 pt-3">
                  <v-btn color="primary" @click="getData"> Filtrele </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-card>
        </v-col>

        <v-col v-if="analiysis_typeModel == 'STRINGBOX'" cols="12">
          <v-card>
            <v-col cols="12" class="pl-0 pr-0 pb-1">
              <v-row>
                <v-spacer />
                <v-col
                  v-if="['Daily'].indexOf(reportTypeModel) > -1"
                  cols="3"
                  sm="2"
                  md="2"
                  class="pr-3"
                >
                  <v-menu
                    ref="menu"
                    v-model="menu"
                    :close-on-content-click="false"
                    :return-value.sync="dates"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-combobox
                        v-model="dates"
                        multiple
                        chips
                        small-chips
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      >
                        <template v-slot:selection="{ item, index }">
                          <span
                            v-if="index === 0"
                            style="max-width: 120px"
                            class="d-inline-block text-truncate"
                            >{{ item }}</span
                          >
                          <span
                            v-if="index === 1"
                            class="grey--text text-caption"
                            >(+{{ dates.length - 1 }})</span
                          >
                        </template>
                      </v-combobox>
                    </template>

                    <v-date-picker
                      v-model="dates"
                      type="month"
                      multiple
                      no-title
                      scrollable
                    >
                      <v-spacer />
                      <v-btn text color="primary" @click="menu = false">
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.menu.save(dates)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-col>

                <v-col
                  v-if="['Minute', 'Hourly'].indexOf(reportTypeModel) > -1"
                  cols="3"
                  sm="2"
                  md="2"
                  class="pr-3"
                >
                  <v-menu
                    ref="menu"
                    v-model="menu"
                    :close-on-content-click="false"
                    :return-value.sync="dates"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-combobox
                        v-model="dates"
                        multiple
                        chips
                        small-chips
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      >
                        <template v-slot:selection="{ item, index }">
                          <span
                            v-if="index === 0"
                            style="max-width: 120px"
                            class="d-inline-block text-truncate"
                            >{{ item }}</span
                          >
                          <span
                            v-if="index === 1"
                            class="grey--text text-caption"
                            >(+{{ dates.length - 1 }})</span
                          >
                        </template>
                      </v-combobox>
                    </template>

                    <v-date-picker v-model="dates" multiple no-title scrollable>
                      <v-spacer />
                      <v-btn text color="primary" @click="menu = false">
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.menu.save(dates)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-col>

                <v-col
                  v-if="reportTypeModel == 'Monthly'"
                  cols="3"
                  sm="1"
                  md="1"
                  class="pr-3"
                >
                  <v-select
                    v-model="yearModel"
                    multiple
                    :items="yearList"
                    label="Yıl"
                  >
                    <template v-slot:selection="{ item, index }">
                      <span
                        v-if="index === 0"
                        style="max-width: 120px"
                        class="d-inline-block text-truncate"
                        >{{ item }}</span
                      >
                      <span v-if="index === 1" class="grey--text text-caption"
                        >(+{{ yearModel.length - 1 }})</span
                      >
                    </template>
                  </v-select>
                </v-col>

                <v-col cols="3" sm="2" md="2" class="pr-3">
                  <v-select
                    v-model="combinerBox"
                    :items="combinerboxList[santralModel]"
                    label="String"
                  />
                </v-col>

                <v-col cols="2" sm="2" md="2" class="pr-3 mt-4">
                  <v-select
                    v-model="measuresModel"
                    :items="measures"
                    label="Ölçü"
                    dense
                  />
                </v-col>

                <v-col cols="1" sm="1" md="1" class="pr-3">
                  <v-select
                    v-model="chartTypeModel"
                    :items="chartType"
                    label="Grafik"
                  />
                </v-col>

                <v-col cols="3" sm="1" md="1" class="pr-3 pt-3">
                  <v-btn color="primary" @click="getData"> Filtrele </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-card>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="12">
          <v-card>
            <v-col cols="12">
              <!-- <echart
                id="general-power-daily"
                class-name="widget-content"
                height="450px"
                width="100%"
                :chart-data="chartData"
              /> -->
              <div
                id="general-power-date-stats"
                style="
                  height: 300px;
                  min-width: 900px;
                  width: 100%;
                  padding: 1px;
                "
              />
            </v-col>
          </v-card>
        </v-col>
      </v-row>

      <v-col cols="12" class="fullgridtable pl-0 pr-0 pb-1 pt-3 ml-0 mr-0">
        <v-card>
          <v-data-table
            :id="santralDataTable"
            dense
            :items-per-page="itemsPerPage"
            :headers="headers"
            :items="desserts"
            class="elevation-1"
          />
        </v-card>
      </v-col>
    </v-container>
    <ExportDocument :export-document-open="exportDocumentOpen" />
  </div>
</template>
<script>
import * as echarts from "echarts";
import moment from "moment";
import ExportDocument from "@/views/components/Shared/ExportDocument.vue";
import i18n from "@/locale";
import * as Stringboxs from "@/api/CombinerBox/Stringbox";

import {
  stringboxMinute,
  stringboxHourly,
  stringboxDaily,
  stringboxMonthly,
  stringboxYearly,
} from "@/api/Request/stringbox";

import {
  inverterHourly,
  inverterMinute,
  inverterDaily,
  inverterMonthly,
  inverterYearly,
} from "@/api/Request/inverter";

import {
  getSantralMinute,
  getSantralHourly,
  getSantralDaily,
  getSantralMonthly,
  getSantralYearly,
} from "@/api/Request/santral";
import helper from "@/api/helper";

export default {
  name: "GeneralStatsHour",
  components: {
    ExportDocument,
  },
  data: () => ({
    chartData: {},
    startDate: moment().format("YYYY-MM-DD"),
    finishDate: moment().format("YYYY-MM-DD"),
    menu: false,
    modal: false,
    menu2: false,
    measuresModel: "DailyEnergy",
    itemsPerPage: 500,
    // eslint-disable-next-line radix
    yearModel: [parseInt(moment().format("YYYY"))],
    monthModel: [],
    measures: [],
    chartType: ["line", "bar"],
    chartTypeModel: "bar",
    componentKey: 1,
    headers: [],
    desserts: [],
    reportTypeModel: "Daily",
    reportType: [],
    weekListModel: 10,
    weekList: [],
    exportDocumentOpen: {
      open: false,
      tableID: null,
    },
    santralDataTable: "santral_Analysis_table",
    chartText: "",
    newData: "true",
    santralModel: "",
    analiysis_type: [
      { text: "Genel İstatistik", value: "generalStats" },
      { text: "İnverter", value: "INVERTER" },
      { text: "Stringbox", value: "STRINGBOX" },
    ],
    analiysis_typeModel: "generalStats",
    combinerBox: "COMBINER01",
    inverter: "INVERTER01",
    dates: [moment().format("YYYY-MM")],
    santralInfo: [],
  }),
  computed: {
    inverterList() {
      const { santralInverterData } = this.$store.getters;
      if (
        !helper.is_Empty(santralInverterData) &&
        !helper.is_Empty(this.santralModel_string)
      ) {
        const inverterList = {};

        Object.keys(santralInverterData).forEach((inv) => {
          inverterList[inv] = [];
          Object.keys(santralInverterData[inv]).forEach((k) => {
            inverterList[inv].push({
              text: santralInverterData[inv][k].INVERTERNAME,
              value: santralInverterData[inv][k].INVERTER,
            });
          });
        });

        return inverterList;
      }
      return [];
    },
    santralDataList() {
      const { santralDataList } = this.$store.getters;
      if (santralDataList === undefined) return;
      // eslint-disable-next-line prefer-destructuring, vue/no-side-effects-in-computed-properties
      this.santralModelString = santralDataList[0];
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.santralModel = santralDataList;
      // eslint-disable-next-line consistent-return
      return santralDataList;
    },

    yearList() {
      return this.$store.getters.clientYearList;
    },
    monthList() {
      return this.$store.getters.month;
    },
    combinerboxList() {
      const { santralStringboxData } = this.$store.getters;
      if (
        !helper.is_Empty(santralStringboxData) &&
        !helper.is_Empty(this.santralModelString)
      ) {
        const stringboxList = {};

        Object.keys(santralStringboxData).forEach((inv) => {
          stringboxList[inv] = [];
          Object.keys(santralStringboxData[inv]).forEach((k) => {
            stringboxList[inv].push({
              text: santralStringboxData[inv][k].STRINGBOXNAME,
              value: santralStringboxData[inv][k].STRINGBOX,
            });
          });
        });

        return stringboxList;
      }
      return [];
    },
  },
  watch: {
    reportTypeModel() {
      this.setData();

      if (this.reportTypeModel === "Daily") {
        this.monthModel = [];
        // eslint-disable-next-line no-unused-expressions
        this.dates = [moment().format("YYYY-MM")];
        // eslint-disable-next-line radix
        this.monthModel.push(parseInt(moment().format("MM")));
      }

      if (["Minute", "Hourly"].indexOf(this.reportTypeModel) > -1) {
        this.dates = [moment().format("YYYY-MM-DD")];
      }
    },
    analiysis_typeModel() {
      this.reportTypeModel = "Minute";

      if (this.analiysis_typeModel === "generalStats") {
        this.setStatsMeasure();
      }
      if (this.analiysis_typeModel === "INVERTER") {
        this.setInverter();
      }
      if (this.analiysis_typeModel === "STRINGBOX") {
        this.setStringMeasure();
      }
    },

    combinerBox() {
      const { stringboxMeasureList } = this.$store.getters;
      this.measures = stringboxMeasureList;
    },
    inverter() {
      const { inverterMeasureList } = this.$store.getters;
      this.measures = inverterMeasureList;
    },
  },

  created() {},
  mounted() {
    this.measures = [
      {
        text: i18n.t("PerformanceRatio"),
        value: "PerformanceRatio",
        unit: "%",
      },
      {
        text: i18n.t("TheoreticalPower"),
        value: "TheoreticalPower",
        unit: "kW",
      },

      {
        text: i18n.t("DailyEnergy"),
        value: "DailyEnergy",
        unit: "kW",
      },
    ];

    this.reportType = [
      { text: "Yıllık", value: "Yearly" },
      { text: "Aylık", value: "Monthly" },
      { text: "Günlük", value: "Daily" },
      { text: "Saatlik", value: "Hourly" },
      { text: "Dakika", value: "Minute" },
    ];

    // eslint-disable-next-line no-use-before-define
    const myVar1 = setInterval(myTimer1, 1000);

    const self = this;
    function myTimer1() {
      const { santral } = self.$store.getters.selectedSantralModel;
      const { santralInfo } = self.$store.getters;

      if (santral !== undefined && Object.keys(santralInfo).length > 0) {
        self.santralModel = santral;
        self.santralInfo = santralInfo;
        clearInterval(myVar1);
        self.getData();
      }
    }
  },
  methods: {
    exportDocument() {
      this.exportDocumentOpen.open = true;
      this.exportDocumentOpen.tableID = this.santralDataTable;
    },

    setData() {
      const formdata = this.selectedStatsParams();
      if (this.analiysis_typeModel === "generalStats") {
        this.getStringData(formdata, this.santralModel);
      }

      if (this.analiysis_typeModel === "INVERTER") {
        const inverterList = formdata.condiniton.INVERTER;

        this.getStringData(formdata, inverterList);
      }

      if (this.analiysis_typeModel === "STRINGBOX") {
        const stringbox = formdata.condiniton.STRINGBOX;

        this.getStringData(formdata, stringbox);
      }
    },
    getData() {
      if (this.analiysis_typeModel === "generalStats") {
        const formdata = this.selectedStatsParams();
        this.getStringData(formdata, this.santralModel);
      }

      if (this.analiysis_typeModel === "INVERTER") {
        const formdata = this.selectedStatsParams();
        const inverterList = formdata.condiniton.INVERTER;

        this.getStringData(formdata, inverterList);
      }

      if (this.analiysis_typeModel === "STRINGBOX") {
        const formdata = this.selectedStatsParams();
        const stringbox = formdata.condiniton.STRINGBOX;

        this.getStringData(formdata, stringbox);
      }
    },

    setStatsMeasure() {
      if (["Minute", "Hourly"].indexOf(this.reportTypeModel) > -1) {
        this.measures = [
          {
            text: i18n.t("PerformanceRatio"),
            value: "PerformanceRatio",
            unit: "%",
          },
          {
            text: i18n.t("TheoreticalPower"),
            value: "TheoreticalPower",
            unit: "kW",
          },
          {
            text: i18n.t("IstantaneousPower"),
            value: "IstantaneousPower",
            unit: "kW",
          },
          {
            text: i18n.t("DailyEnergy"),
            value: "DailyEnergy",
            unit: "kW",
          },
          {
            text: i18n.t("EquivalentTrees"),
            value: "EquivalentTrees",
            unit: "",
          },
          { text: i18n.t("CO2Avoided"), value: "CO2Avoided", unit: "" },
          { text: i18n.t("BarrelsofOil"), value: "BarrelsofOil", unit: "" },
        ];
      } else {
        this.measures = [
          {
            text: i18n.t("PerformanceRatio"),
            value: "PerformanceRatio",
            unit: "%",
          },
          {
            text: i18n.t("DailyEnergy"),
            value: "DailyEnergy",
            unit: "kW",
          },
          {
            text: i18n.t("TheoreticalPower"),
            value: "TheoreticalPower",
            unit: "kW",
          },
          {
            text: i18n.t("EquivalentTrees"),
            value: "EquivalentTrees",
            unit: "",
          },
          { text: i18n.t("CO2Avoided"), value: "CO2Avoided", unit: "" },
          { text: i18n.t("BarrelsofOil"), value: "BarrelsofOil", unit: "" },
        ];
      }
    },
    setInverter() {
      this.chartData = {};
      this.inverterModel = [];
      // eslint-disable-next-line prefer-destructuring
      this.santralModel = this.santralDataList[0];
      const { inverterMeasureList } = this.$store.getters;

      // eslint-disable-next-line prefer-destructuring
      this.measuresModel = inverterMeasureList[0];
      this.measures = inverterMeasureList;
    },

    setStringMeasure() {
      this.measure_List = [];
      this.dcVoltageData = [];
      this.measuresModel = [];
      this.dcVoltageDataDisplay = false;

      // eslint-disable-next-line prefer-destructuring
      this.santralModel = this.santralDataList[0];
      const { stringboxMeasureList } = this.$store.getters;

      // eslint-disable-next-line prefer-destructuring
      this.measuresModel = stringboxMeasureList[0];
      this.measures = stringboxMeasureList;
    },

    getStringData(formdata, device) {
      this.finishDate = moment(this.startDate)
        .add(1, "day")
        .format("YYYY-MM-DD");

      const { measuresModel } = this;
      const { measures } = this;
      this.chartText = "";
      let measureList;
      measures.forEach((measure) => {
        if (measuresModel == measure.value) {
          measureList = measure;
        }
      });

      this.chartData = [];
      const self = this;
      let tempData;

      if (this.reportTypeModel == "Minute") {
        let temp;
        if (this.analiysis_typeModel == "generalStats") {
          temp = getSantralMinute(formdata);
        }

        if (this.analiysis_typeModel == "INVERTER") {
          temp = inverterMinute(formdata);
        }
        if (this.analiysis_typeModel == "STRINGBOX") {
          temp = stringboxMinute(formdata);
        }

        temp.then((_rawData) => {
          tempData = Stringboxs.default.get_minute_data(
            _rawData.data,
            measureList,
            self.chartText,
            self.chartTypeModel,
            self.santralModel,
            device
          );
          this.setOpt(tempData);
        });
      }

      if (this.reportTypeModel == "Hourly") {
        let temp;
        if (this.analiysis_typeModel == "generalStats") {
          temp = getSantralHourly(formdata);
        }

        if (this.analiysis_typeModel == "INVERTER") {
          temp = inverterHourly(formdata);
        }
        if (this.analiysis_typeModel == "STRINGBOX") {
          temp = stringboxHourly(formdata);
        }
        temp.then((_rawData) => {
          tempData = Stringboxs.default.get_hourly_data(
            _rawData.data,
            measureList,
            self.chartText,
            self.chartTypeModel,
            self.santralModel,
            device
          );
          this.setOpt(tempData);
        });
      }

      if (this.reportTypeModel === "Daily") {
        let temp;

        if (this.analiysis_typeModel === "generalStats") {
          temp = getSantralDaily(formdata);
        }

        if (this.analiysis_typeModel === "INVERTER") {
          temp = inverterDaily(formdata);
        }
        if (this.analiysis_typeModel === "STRINGBOX") {
          temp = stringboxDaily(formdata);
        }
        temp.then((_rawData) => {
          tempData = Stringboxs.default.get_daily_data(
            _rawData.data,
            measureList,
            self.chartText,
            self.chartTypeModel
          );
          this.setOpt(tempData);
        });
      }

      if (this.reportTypeModel === "Monthly") {
        let temp;
        if (this.analiysis_typeModel === "generalStats") {
          temp = getSantralMonthly(formdata);
        }
        if (this.analiysis_typeModel === "INVERTER") {
          temp = inverterMonthly(formdata);
        }
        if (this.analiysis_typeModel === "STRINGBOX") {
          temp = stringboxMonthly(formdata);
        }
        temp.then((_rawData) => {
          tempData = Stringboxs.default.get_monthly_data(
            _rawData.data,
            measureList,
            self.chartText,
            self.chartTypeModel,
            self.yearModel
          );
          this.setOpt(tempData);
        });
      }

      if (this.reportTypeModel == "Yearly") {
        let temp;

        if (this.analiysis_typeModel == "generalStats") {
          temp = getSantralYearly(formdata);
        }
        if (this.analiysis_typeModel == "INVERTER") {
          temp = inverterYearly(formdata);
        }
        if (this.analiysis_typeModel == "STRINGBOX") {
          temp = stringboxYearly(formdata);
        }
        temp.then((_rawData) => {
          tempData = Stringboxs.default.get_yearly_data(
            _rawData.data,
            measureList,
            self.chartText,
            self.chartTypeModel,
            self.yearList
          );
          this.setOpt(tempData);
        });
      }
    },
    selectedStatsParams() {
      const { reportTypeModel } = this;
      this.headers = [];
      let headers = [];
      let params = {};

      if (reportTypeModel == "Yearly") {
        headers = [
          { text: "Yıl", value: "date" },
          { text: this.measuresModel, value: "val" },
        ];
        const date = [];

        this.yearList.forEach((y) => {
          date.push(y);
        });
        const text1 = `${this.yearList[0]}`;
        this.santralDataTable = `${text1}-DateAnalysisDataTable`;
        this.headers = headers;

        params = {
          condiniton: {
            date: this.yearList,
            santral: this.santralModel,
            measures: this.measuresModel,
          },
        };
      }

      if (reportTypeModel == "Monthly") {
        headers = [{ text: "Ay", value: "date" }];
        const date = [];

        this.yearModel.forEach((y) => {
          const mL = [];
          for (let m = 1; m < 13; m++) {
            mL.push(m);
          }
          date.push({
            year: y,
            month: mL,
          });
          headers.push({
            text: y,
            value: y.toString(),
          });
        });

        const text1 = `${this.yearModel[0]}`;
        this.santralDataTable = `${text1}-DateAnalysisDataTable`;
        this.headers = headers;

        params = {
          condiniton: {
            date,
            santral: this.santralModel,
            measures: this.measuresModel,
          },
        };
      }

      if (reportTypeModel == "Daily") {
        headers = [{ text: "Gün", value: "date" }];
        const text1 = `${this.yearModel}-${this.monthModel[0]}`;
        this.santralDataTable = `${text1}-DateAnalysisDataTable`;
        const temp = {};

        this.dates.forEach((k) => {
          const _temp = k.split("-");
          const y = parseInt(_temp[0]);
          const m = parseInt(_temp[1]);
          if (!temp[_temp[0]]) {
            temp[y] = [];
          }
          temp[y].push(m);
          const text = `${y}-${m}`;

          headers.push({
            text,
            value: text,
          });
        });

        const date = [];
        Object.keys(temp).forEach((d) => {
          date.push({
            year: d,
            month: temp[d],
          });
        });

        this.headers = headers;

        params = {
          condiniton: {
            date,
            santral: this.santralModel,
            measures: this.measuresModel,
          },
        };
      }

      if (reportTypeModel == "Hourly") {
        this.santralDataTable = `${this.dates[0]}-santralDataTable`;
        headers = [{ text: "Gün", value: "date" }];

        if (this.dates.length > 5) {
          alert("En fazla 5 seçim yapabilirsiniz.");
          return;
        }

        this.dates.sort();
        this.dates.forEach((d) => {
          headers.push({
            text: d,
            value: d,
          });
        });

        this.headers = headers;
        params = {
          condiniton: {
            dates: this.dates,
            santral: this.santralModel,
            measures: this.measuresModel,
          },
        };
      }

      if (reportTypeModel == "Minute") {
        this.santralDataTable = `${this.dates[0]}-DateAnalysisDataTable`;
        headers = [{ text: "Gün", value: "date" }];
        const measuresModel = [];
        measuresModel.push(this.measuresModel);
        measuresModel.push("Time");

        if (this.dates.length > 5) {
          alert("En fazla 5 seçim yapabilirsiniz.");
          return;
        }

        this.dates.sort();
        this.dates.forEach((d) => {
          headers.push({
            text: d,
            value: d,
          });
        });

        this.headers = headers;
        params = {
          condiniton: {
            dates: this.dates,
            santral: this.santralModel,
            measures: this.measuresModel,
            maxcount: 5000,
          },
        };
      }

      if (this.analiysis_typeModel == "INVERTER") {
        params.condiniton.INVERTER = this.inverter;
      }

      if (this.analiysis_typeModel == "STRINGBOX") {
        params.condiniton.STRINGBOX = this.combinerBox;
      }
      params.condiniton.maxcount = 500;
      this.headers = headers;
      return params;
    },

    setOpt(tempData) {
      const chartDom = document.getElementById("general-power-date-stats");
      const myChart = echarts.init(chartDom);
      tempData.then((res) => {
        myChart.setOption(res.options, true);
        // this.chartData = res.options;
        this.desserts = res.desserts;
        this.componentKey += 1;
        this.forceRerender();
      });
    },
    forceRerender() {
      this.renderComponent = false;
      this.$nextTick().then(() => {
        this.renderComponent = true;
      });
    },
  },
};
</script>

<style scoped>
.theme--dark
  .fullgridtable
  .theme--light.v-data-table
  tbody
  tr:not(:last-child) {
  border-bottom: none;
}

.fullgridtable .v-data-table th {
  border: thin solid #0000001f;
}

.fullgridtable .v-data-table td {
  border: thin solid #0000001f;
}

.fullgridtable.theme--dark .v-data-table th,
.fullgridtable.theme--dark .v-data-table td {
  border: thin solid hsla(0, 13%, 45%, 0.12);
}

.fullgridtable .theme--light.v-data-table tbody tr:not(:last-child) {
  border-bottom: none;
}

.fullgridtable.fullorder .v-data-table th,
.fullgridtable.fullorder .v-data-table td {
  border-bottom: 0 !important;
}

.fullgridtable.fullorder .v-data-table td:not(:last-child),
.fullgridtable.fullorder .v-data-table th:not(:last-child) {
  border-right: 0 !important;
}
</style>
